.actionPerformed {
    min-height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #00000029;
}

/* MOBILE SCREEN */

@media screen and (max-width: 375px) {
    .actionPerformed {
        min-height: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 820px) {
    .actionPerformed {
        min-height: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
}