.boxImageVM {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 170px;
    padding-bottom: 0px;
    position: relative;
    margin-bottom: 0;    
    border: 5px solid #f0f0f0;
}