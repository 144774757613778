.item {
    a {
        text-decoration: none;
        font-size: 14px;
        color:#757575;
        padding:20px 17px;
        display:block;    
        
        &:hover {
            background:#eee;
        }
    }
}

a[class="active"] {
    background:#eee;
}