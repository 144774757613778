.videoPlayer {
    position: relative;
    width: 100%;
    height: 44vh;
    overflow: hidden;
    z-index: 0;
    outline: 0;
    color: #eee;
    text-align: left;
    direction: ltr;
    font-size: 11px;
    line-height: 1.3;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    touch-action: manipulation;
    -ms-high-contrast-adjust: none;
    margin-bottom: 0 !important;
    display: block;
}