/* MODAL CADASTRAR DOCUMENTO */
.modal-obras-title {
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #0F172A;
    text-align: end;
}

.modal-form-cadastro-doc-main {
    display: flex;
    flex-direction: row;
}

.modal-form-cadastro-doc-1 {
    width: 456px;
    height: 400px;
    padding: 0 10px 0 10px;
}

/* MODAL APROVAR DOCUMENTO */
.obras-input-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

/* MODAL ANEXO */

.modal-anexo-span-red {
    background: #E55050;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: fit-content;
    height: 25px;
    border-radius: 100px;
    padding: 5px 10px 5px 10px;
    gap: 10px;
    border: none;

    color: #FFFFFF;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    cursor: default;
}

.modal-anexo-span-green {
    background: #51C07D;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: fit-content;
    height: 25px;
    border-radius: 100px;
    padding: 5px 10px 5px 10px;
    gap: 10px;
    border: none;

    color: #FFFFFF;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    cursor: default;
}

.modal-anexo-span-yellow {
    background: #F6CA56;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: fit-content;
    height: 25px;
    border-radius: 100px;
    padding: 5px 10px 5px 10px;
    gap: 10px;
    border: none;
    margin: 2%;

    color: #FFFFFF;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    cursor: default;
}

.modal-anexo-span-gray {
    background: #E2E8F0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: fit-content;
    height: 25px;
    border-radius: 100px;
    padding: 5px 10px 5px 10px;
    gap: 10px;
    border: none;
    margin: 2%;

    color: #FFFFFF;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    cursor: default;
}

/* MODAL COMENTARIO */

.comentario-obras-container {
    /* width: 548px;
    height: 84px; */

    border-radius: 8px;
    padding: 10px 15px 10px 15px;
    gap: 10px;
    background-color: #F8FAFC;
    margin: 2% 0 2% 0;
}

.comentario-obras-span-strong {
    font-weight: 700;
    font-size: 10px;
    line-height: 12.0px;
}

.comentario-obras-p-blue {
    font-weight: 700;
    font-size: 13px;
    line-height: 15.73px;
    color: #3186C3;
    margin: 5px 0 10px;

    a {
        text-decoration:none;
        margin-bottom:5px;
    }

    [data-box_icon] {
        pointer-events:none;

        [data-icon] {
            opacity:1;
            
            svg {
                color:#3186C3;
            }
        }
    }
}

.comentario-obras-p-black {
    font-weight: 600;
    font-size: 13px;
    line-height: 15.73px;
    margin: 2% 0 2% 0;
    color: #000000;
}

.comentario-obras-p-disabled {
    font-weight: 400;
    font-size: 13px;
    margin: 5px 0 10px;
    color: #334155;
}

.comentario-obras-div {
    display: flex;
    justify-content: space-between;
}

.comentario-obras-span-disabled {
    font-weight: 400;
    font-size: 11px;
}

.comentario-obras-span-disabled.date {
    color:#94A3B8;
}

.comentario-obras-span-blue {
    color: #3186C3;
}

/* MODAL INFORMAÇÕES OBRA */

.informacoes-obras-span{
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52;
    color: #0F172A;
  }

.informacoes-div{
    height: 100px;
    border-bottom: 1px solid #e2e8f0;
    margin-bottom: 2%;
}
.informacoes-obras-p{
    /* height: 45px; */
    font-size: 13px;
    font-weight: 400;
    line-height: 15.73px;
    color: #6F7B8A;

}

.informacoes-obras-bottom-div{
    height: 45px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.1px;
    color: #3186C3;
}