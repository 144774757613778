.boxImageNews {
    cursor: pointer;
    display: block;
    /* max-height: 700px; */
    max-width: 700px;
    position: relative;
}

.boxImageNews .swiper-button-next {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.boxImageNews .swiper-button-prev {
    left: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.boxImageNews .swiper-wrapper {
    padding-top: 0!important;
}

/* MOBILE SCREEN */
@media screen and (max-width: 375px) {
    .boxImageNews {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .boxImageNews .swiper-button-prev {
        left: 0;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 820px) {
    .boxImageNews {
        max-height: 100%;
        max-width: 100%;
    }
}

@media screen and (max-width: 912px) {
    .boxImageNews {
        max-width: 100%;
    }
}