.galeria-div-container{
    position: relative;
    transition: transform ease .3s, box-shadow ease .3s;
    flex:10%;
    aspect-ratio: 1/1;
    max-width:10%;
}

.galeria-div-container:hover {
    transform: scale(1.05);
    box-shadow: 0 15px 50px -25px rgba(0, 0, 0, .7);
    position:relative;
}


.galeria-first-container {
    width: 100%;
    height:100%;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    transition: transform ease .3s, box-shadow ease .3s;
    border: 3px solid white;
    background-color: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
}

.galeria-image-a-link {
    position: static;
    width: calc(100%);
    height: calc(100%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    /* border-radius: 6px; -- original */
    border-radius: 4.5px;
    background-color: #eeeeee;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-decoration: none !important;
    color: #757575;
    transition: transform ease .5s, ease .5s;
}

.galeria-image-a-link:hover {
    background-color: #ddd;
}

.input-galeria {
    border: none;
    background: none;
}

.input-galeria>.buttonFile {
    background: none;
}

.span-delete-galeria{
    position: absolute!important;
    height: 28px;
    width: 28px;
    border-radius: 100%;
    top: -10px;
    right: -10px;
    background-color: white;
    z-index: 5;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* CONTAINER DO INPUT  */

.galeria-second-container {
    width: 200px;
    height: 180px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    transition: transform ease .3s, box-shadow ease .3s;
    border: 3px solid white;
    background-color: #eeeeee;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    transition: transform ease .5s, ease .5s;
}

.galeria-second-container:hover {
    background-color: #ddd;
}

/* RESPONSIVO */
@media screen and (max-width: 1750px) {
    .galeria-div-container{
        flex: 15%;
        max-width: calc(14% - 13px);
    }
}

@media screen and (max-width: 1750px) {
    .galeria-div-container{
        flex: 15%;
        max-width: calc(14% - 13px);
    }
}

@media screen and (max-width: 1580px) {
    .galeria-div-container{
        max-width: calc(16% - 4px);
    }
}

@media screen and (max-width: 1240px) {
    .galeria-div-container{
        flex: 19%;
        max-width: calc(19% - 6px);
    }
}

@media screen and (max-width: 1120px) {
    .galeria-div-container{
        flex: 19%;
        max-width: calc(19% - 8px);
    }
}

/* MOBILE */
@media screen and (max-width: 500px) {
    .galeria-div-container {
        flex: 50%;
        max-width: calc(50% - 10px);
    }
}