.userCommentDiv {
    width: calc(100% - 55px);
    height: fit-content;
    margin-left: 2%;
    background-color: #f6f6f6;
    padding: 10px 15px;
    position: relative;
    text-align: left;
}



