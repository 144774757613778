.container {
    background:#fff;
    text-align:center;
    border: 1px solid #eee;
    border-radius: 6px;
    box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);
    overflow:hidden;

    .video {
        background:#000;

        iframe {
            width:100%;
            height:800px;
            max-height:calc(100vh - 282px);
            display:block;
        }
    }

    .docs {
        background:#000;

        iframe {
            width:100%;
            height:800px;
            max-height:calc(100vh - 282px);
            display:block;
        }
    }
}

.iframe {
    width:100%;
    height:calc(100vh - 190px);
    border-radius:6px;
    overflow:hidden;
    background:#d1d1d1;
    display:block;
    padding:0;
} 

.info {
    text-align: center;
    margin: 0 1.5rem 1rem 1.5rem;
    font-size: 1.125rem;
    color: #878c9b;
    font-weight: 400;
    line-height: 1.6;

    a {
        color: #878c9b!important;
    }
}

/* MOBILE */
@media screen and (max-width:500px){
    .container {    
        .video {    
            iframe {
                max-height:calc(100vh - 370px);
            }
        }
    
        .docs {    
            iframe {
                max-height:calc(100vh - 370px);
            }
        }
    }
}