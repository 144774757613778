.newsMainContainer {
    list-style: none;
    background-color: #fff;
    width: 700px;
    max-width:100%;
    margin: 0 0 45px 0;
    position: relative;
    box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 15%);
    transition: ease 0.5s;
    
    &.loading {
        height:500px;
        background:#d9dde0;
        pointer-events:none;
        margin-bottom:40px;

        &:after {
            content:'';
            position:absolute;
            top:0;
            left:0;
            width:100px;
            height:100%;
            background:rgba(255,255,255,0.3);
            filter:blur(10px);
            z-index:1;
            animation: linear infinite;
            animation-name: shine;
            animation-duration:.5s;
            -webkit-animation: linear infinite;
            -webkit-animation-name: shine;
            -webkit-animation-duration:.5s;
        }
    }
}

@keyframes shine {
    0% {
        left:-200px;
    }
    100% {
        left:calc(100% + 200px);
    }
}

@-webkit-keyframes shine {
    0% {
        left:-200px;
    }
    100% {
        left:calc(100% + 200px);
    }
}

.newsMainContainer:hover {
    transition-duration: .5s;
    transition-timing-function: ease;
    transition-delay: 0s;
    box-shadow: 0px 0px 18px 2px rgba(186, 186, 186, 0.56);
    -webkit-box-shadow: 0px 0px 18px 2px rgba(186, 186, 186, 0.56);
    -moz-box-shadow: 0px 0px 18px 2px rgba(186, 186, 186, 0.56);
}

/* @media screen and (max-width: 375px) {
    .newsMainContainer{
        width: 100%;
    }
} */

@media screen and (max-width: 540px) {
    .newsMainContainer{
        width: 100%;
    }
}

@media screen and (max-width: 820px) {
    .newsMainContainer {
        width: calc(100% - 80px);
    }
}

@media screen and (max-width: 1200px){
    .newsMainContainer{
        width:100%;
    }
}

@media screen and (max-width: 1800px){
    .newsMainContainer{
        width: 700px; /* quando mostra as inativas a tela fica deformada */
    }
}