
.searchImageVM{
    color: gray!important;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1!important;
}

/* color: #fff!important; cor original */