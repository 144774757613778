.commentsSection {
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-top: 3%;
}

@media screen and (min-width: 1100px) and (max-width: 1400px) {
    .commentsSection{
        max-width: 640px;
        min-height: 60px;
    }
}
