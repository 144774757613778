.message {
    position:relative;
    padding-right:40px;

    &:after {
        content:'';
        position:absolute;
        top:0;
        right:20px;
        width:1px;
        height:calc(100% - 35px);
        border-right:1px dashed #ddd;
    }
}

.filters {
    // display:flex;
    flex-wrap:wrap;
    align-items:flex-start;
    gap:10px;
    margin-top:5px;
    margin-bottom:15px;

    >div {
        flex:0 0 calc(50% - 5px);
    }

    &.flex_33 {
        >div {
            flex:0 0 calc(33.3333% - 7px);
        }
    }

    &.flex_50 {
        >div {
            flex:0 0 calc(50% - 5px);
        }
    }
}

.separator {
    position:relative;
    text-align:center;
    margin:20px 0;
    display:block;
    min-height:20px;

    &:before {
        content:'';
        position:absolute;
        top:50%;
        left:0;
        width:100%;
        height:1px;
        background:#e5e9ec;
    }

    span {
        font-weight: 400;
        font-size: 13px;
        color: #aaa;
        position:relative;
        display:inline-block;
        background:#fff;
        padding:0 10px;
    }
}

.shortcut_container {
    display:flex;
    flex-wrap:wrap;
    gap:0 24px;

    >div {
        flex:0 0 calc(20% - 20px);
        max-width:calc(20% - 20px);
        cursor:pointer;
    }
}

.importer {
    position:relative;

    input {
        position:absolute;
        top:0;
        left:0;
        opacity:0;
        width:100%;
        height:100%;
        z-index:-1;
    }
}

// MOBILE
@media screen and (max-width:500px){
    .message {
        padding-top:60px;
        padding-right:0;
    
        &:after {
            top:25px;
            right:25px;
            width:calc(100% - 50px);
            height:1px;
            border-top:1px dashed #ddd;
            border-right:none;
        }
    }

    .shortcut_container {    
        >div {
            flex:0 0 calc(50% - 12px);
            max-width:calc(50% - 12px);
        }
    }
}