.moduleCard_container {
    display:flex;
    flex-wrap:wrap;
    gap:25px;
    width:100%;
}

.favorite_container {
    margin-bottom:50px;

    .moduleCard_container {
        >div {
            flex:0 0 calc(33.3333% - 17px);
        }
    }
}

.other_container {
    .moduleCard_container {
        >div {
            flex:0 0 200px;
        }
    }
}

.editor_container {
    .moduleCard_container {
        >div {
            width:200px;
            flex:1;
        }
    }
}

.editor {
    position:fixed;
    right:0;
    top:0;
    background:#f0f0f0;
    height:100vh;
    box-shadow:0 0 40px -20px rgba(0,0,0,0.5);
    border-left:1px solid #d8d8d8;
    transform:translateX(100%);
    transition:transform ease .5s;

    .editor_container {
        position:relative;
        top:0;
        left:0;
        width:100%;
        height:100%;
        padding:30px;
        overflow-y:auto;
    }

    .editor__button {
        position:absolute;
        top:30px;
        right:100%;
        background:#f0f0f0;
        display:flex;
        align-items:center;
        justify-content:center;
        width:50px;
        height:50px;
        font-size:19px;
        border-top-left-radius:6px;
        border-bottom-left-radius:6px;
        border:1px solid #d8d8d8;
        border-right:none;
        box-shadow:-15px 0 30px -10px rgba(0,0,0,0.3);
        cursor:pointer;

        >div {
            pointer-events:none;
        }

        &:hover {
            background:#e8e8e8;
        }
    }

    .moduleCard_container {
        flex-direction: column;
        gap:15px;
        display:block;

        >div {
            margin-bottom:15px;
        }
    }

    &.active {
        transform:translateX(0);
    }
}

@media screen and (max-width:500px){
    .favorite_container {    
        margin-bottom:30px;
        
        .moduleCard_container {
            >div {
                flex:0 0 100%;
            }
        }
    }
    
    .other_container {
        .moduleCard_container {
            >div {
                flex:0 0 calc(50% - 13px);
            }
        }
    }
    
    .editor {
        display:none;
    }
}