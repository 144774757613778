.container {        
    overflow:hidden;

    [data-fullheight] {
        box-shadow:none;
    }

    .bg__white {
        background:#fff;
        border: 1px solid #eee;
        border-radius: 6px;
        padding:30px;
        box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);
    }

    &.quiz {
        width:700px;
        max-width:100%;
        margin:0 auto;
    }
}

.info {
    text-align: center;
    margin: 0 1.5rem 1rem 1.5rem;
    font-size: 1.125rem;
    color: #878c9b;
    font-weight: 400;
    line-height: 1.6;    
    
    [data-box_icon] {
        pointer-events:none;
    }
}

h2 {
    &.info {
        font-size: 1.5rem;
    }
}