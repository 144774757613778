.avulsos {
    display:flex;
    flex-wrap:wrap;
    gap:17px;

    >div {
        flex:0 0 calc(33.33333% - 12px);
        max-width:calc(33.33333% - 12px);
        margin-bottom:0;
        cursor:pointer;

        >div {
            width:100%!important;
            margin-bottom:0;
        }
    }
}

.logo {
    max-width:100px;
    max-height:26px;
    margin-right:10px;
}

@media screen and (min-width:768px){
    .avulsos {
        width:calc(100vw - 80px)!important;
    }
}

@media screen and (min-width:800px){
    .avulsos {
        width:calc(50vw - 68px)!important;
    }
}

@media screen and (min-width:1143px){
    .avulsos {
        width:calc(33.3333vw - 50px)!important;
    }
}

@media screen and (min-width:1650px){
    .avulsos {
        width:calc(25vw - 43px)!important;
    }
}

@media screen and (min-width:1840px){
    .avulsos {
        width:calc(20vw - 36px)!important;
    }
}

@media screen and (min-width:2200px){
    .avulsos {
        width:calc(17vw - 40px)!important;
    }
}

@media screen and (min-width:2600px){
    .avulsos {
        width:calc(15vw - 50px)!important;
    }
}

@media screen and (min-width:3000px){
    .avulsos {
        width:calc(13vw - 48px)!important;
    }
}

.separator {
    display:block;
    overflow:hidden;
    text-align:center;
    margin:24px 0;

    span {
        color:#BDC3CB;
        font-size:13px;
        position:relative;

        &:before,
        &:after {
            content:'';
            position:absolute;
            top:calc(50% - 1px);            
            width:200px;
            height:1px;
            background:#BDC3CB;
        }

        &:before {
            right:100%;
            margin-right:10px;
        }

        &:after {
            left:100%;
            margin-left:10px;
        }
    }
}

.rate_txt {
    display:block;
    max-width:350px;
    margin:0 auto;
    text-align:center;
    padding:20px 10px;

    p {
        font-size:18px; 
        color:#81878D;

        span {
            color:#10459E;
        }
    }

    .rate_icons {
        padding:0 10px;
        
        p {
            text-align:left;
            margin-bottom:10px;

            [data-box_icon] {
                pointer-events:none;

                lord-icon {
                    width:24px!important;
                    height:24px!important;
                }
            }
        }
    }
}

.swiper_separator {
    padding-right:48px;

    &:after {
        content:'';
        position:absolute;
        top:0;
        right:12px;
        width:1px;
        height:calc(100% - 14px);
        background:#BDC3CB;
    }
}

@media screen and (max-width:500px){
    .swiper_separator {
        padding-right:0;
    
        &:after {
            display:none;
        }
    }
}