.dash-obras-div-atualizacao {
    width: auto;
    height: auto;
    border-radius: 8px;
    padding: 10px 15px 10px 15px;
    background-color: #F8FAFC;
    gap: 10px;
    margin:0;
}

.dash-obras-div-atualizacao-p {
    font-size: 13px;
    font-weight: 600;
    line-height: 15.73px;
    color: black;
}

.dash-obras-div-atualizacao-p-disabled {
    font-size: 14px;
    font-weight: 400;
    margin-bottom:10px;
    color: #94A3B8;
}
.dash-obras-div-p{
    display: flex;
    flex-direction: row;
}
.dash-obras-icon {
    font-size: 8px;
}

.dash-obras-atualizacoes-span-div {
    display: flex;
    align-items:center;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 600;
    line-height: 15.73px;
    color: black;

    svg {
        color:#3186C4;
    }
}

.dash-obras-atualizacoes-span-data-enabled {
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    color: #000000;
}

.dash-obras-atualizacoes-span-data-disabled {
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    color: #94A3B8;
}

.btn-doc-novos-atualizacao-red {
    background-color: #E55050;
    border: none;
    width: 35px;
    height: 15px;
    top: 2px;
    left: 5px;
    border-radius: 100px;
    color: #FFFFFF;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;

    font-size: 8px;
    font-weight: 600;
    line-height: 9.68px;
}

.btn-doc-novos-atualizacao-blue {
    color: #3186C3;
    border: none;
    width: 35px;
    height: 15px;
    top: 2px;
    left: 5px;
    border-radius: 100px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 8px;
    font-weight: 600;
    line-height: 9.68px;
}