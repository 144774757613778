.cardBtn{
    position: absolute;
    top: -15px;
    left: -15px;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow:6px 9px 25px -11px rgba(0, 0, 0, 0.3);
}

.containerLista{
    display:flex;
    flex-wrap: wrap;
    gap:20px;
}

.darkMainMenu {
    color: #182141;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.75px;
    text-transform: uppercase;
    cursor: pointer;
}

.editDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.date{
    max-width:calc(100% - 50px);
    text-align:center;
    position: absolute;
    top: -10px;
    right: 0px;
    background-color: #aaa;
    color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    padding: 6px 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6;
    gap: 2px;
    display:flex;
    align-items:center;

    &.date__red {
        background-color: #dc3545;
    }

    &.date__green {
        background-color: #28a745;
    }

    [data-box_icon] {
        pointer-events:none;
    }

    svg {
        color:#fff;
        margin-right:5px;
        position:relative;
        top:-1px;
    }
}

@media screen and (max-width: 500px) {
    .redTextDiv{
        width: 250px;
        height: auto;
        font-size: 14px;
        padding: 3px 3px;
    }
}