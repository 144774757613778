.title {
    font-weight:400;
    margin:0 0 15px;
    font-size:18px;
    color:#81878d;
    position:relative;
    z-index:5;
    display:flex;
    justify-content:space-between;
    align-items:center;

    span {
        max-width:calc(100% - 50px);
        text-overflow:ellipsis;
        overflow:hidden;
        white-space:nowrap;
        display:inline-block;
        padding-top:5px;
        padding-right:10px;
    }

    button {
        span {
            padding:2px 0;
        }
    }

    .icon {
        span {
            max-width:none;
        }
    }

    &.no__overflow {
        span {
            overflow:visible;
        }
    }

    &.bordered {
        border-bottom:1px solid #ccc;
        padding-bottom:10px;
    }

    &.bold {
        font-weight:400;
        font-size:20px;
    }

    &.active {
        color:#0090d9;
        font-weight:400;
    }

    [class="small text-secondary"] {
        position:relative;
        top:4px;
        font-size:18px!important;
        padding-right:0;
    }

    svg {
        font-size:17px;
        cursor:pointer;        
        
        &[class="text-secondary"] {
            opacity:.25;
        }
    }

    [data-icon] {
        margin-left:8px;

        &:before {
            left:calc(50% - 14px);
        }
    }

    [data-select], [data-input] {
        width:auto;
        padding:0 7px 0;
        background:transparent!important;

        label {
            font-size:1.4rem;
            font-weight:300;
            color:#4c505b;
        }
    }

    [data-select] {
        padding-right:0;
        
        >div>div {
            padding:0 4px;

            >div:last-child {
                span:first-child {
                    display:none;
                }

                div:last-child {
                    padding:5px 0;
                }
            }
        }
    }

    button {
        height:36px;
    }

    &.wrap {
        span {
            overflow:initial;
            white-space:initial;
            max-width:100%;
            padding-right:0;
        }
    }

    &.loader{
        background:#d9dde0;
        width:300px;
        max-width:100%;
        height:30px;
        margin-bottom:30px;
        box-shadow:none;
        overflow:hidden;
        position:relative;

        &:after {
            content:'';
            position:absolute;
            top:0;
            left:0;
            width:100px;
            height:100%;
            background:rgba(255,255,255,0.3);
            filter:blur(10px);
            z-index:1;
            animation: linear infinite;
            animation-name: shine;
            animation-duration:.5s;
            -webkit-animation: linear infinite;
            -webkit-animation-name: shine;
            -webkit-animation-duration:.5s;
        }
    }
}

.title {
    [data-select] {
        svg {
            font-size:24px;
            margin-left:0;
        }
    }
}

/* MOBILE */
@media screen and (max-width:768px){
    .title {
        [data-select] {
            padding-top:3px;
            padding-bottom:3px;
        }

        [data-icon] {
            margin-left:15px;
        }

        svg {
            font-size:24px;
        }

        button {
            span {
                padding:0;
            }
        }

        >span {
            >div {
                // max-width: calc(100% - 10px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }    
}

@keyframes shine {
    0% {
        left:-200px;
    }
    100% {
        left:calc(100% + 200px);
    }
}

@-webkit-keyframes shine {
    0% {
        left:-200px;
    }
    100% {
        left:calc(100% + 200px);
    }
}

/* PRINT */
@media print {
    .title {
        [data-icon] { 
            display:none;
        }
    }
}