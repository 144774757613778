.cardContainer {
    background: white;
    border-radius: 6px;
    opacity: 1 !important;
    width: calc(25% - 20px);
    text-align: center;
    align-items: center;
    transition: transform ease .3s, box-shadow ease .3s;
    color: #757575;
    font-weight: 100;
    position: relative;

    h1 {
        text-align:center;
    }

    &.loading {
        overflow:hidden;
        background-color:rgba(0,0,0,0.02);
        pointer-events:none;

        .imageCard {
            background-image:none!important;
            background-color:rgba(0,0,0,0.02);
        }

        .edit {
            display:none;
        }

        .titleDiv {  
            h1 {
                display:none;
            }
        }

        &:after {
            content:'';
            position:absolute;
            top:0;
            left:0;
            width:100px;
            height:100%;
            background:rgba(255,255,255,0.3);
            filter:blur(10px);
            z-index:1;
            animation: linear infinite;
            animation-name: shine;
            animation-duration:.5s;
            -webkit-animation: linear infinite;
            -webkit-animation-name: shine;
            -webkit-animation-duration:.5s;
        }
    }

    &.empty {
        min-height:300px;
    }
}

@keyframes shine {
    0% {
        left:-200px;
    }
    100% {
        left:calc(100% + 200px);
    }
}

@-webkit-keyframes shine {
    0% {
        left:-200px;
    }
    100% {
        left:calc(100% + 200px);
    }
}

.cardContainer:hover {
    cursor: pointer;
    transform: scale(1.03);
    transition-duration: 0.3s;
    transition-delay: 0s;
    box-shadow: 0 15px 50px -25px rgb(0 0 0 / 30%);
    -webkit-box-shadow: 0 15px 50px -25px rgb(0 0 0 / 30%);
    -moz-box-shadow: 0 15px 50px -25px rgb(0 0 0 / 30%);
}


@media screen and (max-width: 1400px) {
    .cardContainer{
        width: calc(33.33% - 20px);
    }
}
@media screen and (max-width: 1100px) {
    .cardContainer {
        width: calc(50% - 20px);
    }
}
@media screen and (max-width: 500px) {
    .cardContainer{
        width:100%;
    }
}