.main-container-cracha {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* border: 2px solid red; */
}

.image-container-cracha{
    width: 100%;
    display: block;
    text-align: center;
    background-color: #f7f6f6;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 10px;
}
.image-container-cracha img {
    /* width: 200px;
    height: 200px;
    object-fit: cover;
    align-self: center;
    margin-bottom: 16px; */
    max-width: 80px;
    width: 80px;
    display: block;
    margin: 0 auto;
}
.main-div-cracha p {
    font-weight: 500;
}
.main-div-cracha p span{
    font-weight: 300;
}
.red {
    color: red!important;
}
.button-div-cracha {
    display: flex;
    gap: 20px;
}

.image-error{
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px ;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    font-size: 14px;
}

.image-error a {
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
}

.image-error a:hover{
    text-decoration: underline;
    text-decoration-color: #721c24;
}

.img-funcionario{
    border-radius: 4px!important;
    z-index: 10;
}