/* MAIN CONTAINER */

.h4-module {
    align-self: flex-end;
}

.span {
    align-self: flex-end;
}

.div-modulo-1 {
    /* background-color: #e5e9ec; */
    /* background-color:#f5f7f8; */
    width: 80%;
    height: 96vh;
    margin: 0 10% 0 10%;
    display: flex;
}

.div-modulo-2 {
    /* background-color: #f5f7f8; */
    width: 80%;
    height: 100%;
}

.div-modulo-2-a {
    /* background-color: #f5f7f8; */
    display: flex;
    flex-direction: column;
}

.modulo-2-a-b {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.div-modulo-2-b {
    height: 60%;
}

.div-modulo-3 {
    width: 20%;
    padding-top: 30px;
}

.topMenu {
    margin-bottom: 2%;
}

/* BOTTOM MENU */
/* TEXTO */
.bottomModuleTitle {
    font-size: 16px;
    margin-bottom:16px;
    font-weight: 600;
    color: #6C737B;
}

.menuModule {
    height: 500px;
    width: 500px;
    border: 2px solid black;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 2%;
}

.teste-modulos {
    justify-content: space-between;
}

@media screen and (max-width: 900px) {
    .div-modulo-1 {
        width: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;
    }

    .div-modulo-1 {
        display: flex;
        flex-direction: column;
    }

    .div-modulo-2 {
        width: 100%;
        height: 70%;
    }

    .div-modulo-2-a {
        height: 60%;
        width: 100%;
    }

    .div-modulo-2-b {
        height: 40%;
    }

    .div-modulo-3 {
        width: 100%;
        height: 30%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10px;
        justify-content: space-evenly;
    }

    .modulo-3-b {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

/* entrando no 600 1 - 40% / 2 - 30% / 3 - 30% */

@media screen and (max-width: 600px) {
    .div-modulo-1 {
        display: flex;
        flex-direction: column;
    }

    .div-modulo-2 {
        width: 100%;
        height: 70%;
    }

    .div-modulo-2-a {
        height: 60%;
        width: 100%;
    }

    .div-modulo-2-b {
        height: 40%;
    }

    .div-modulo-3 {
        width: 100%;
        height: 30%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10px;
        justify-content: space-between;
    }

    .modulo-3-b {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}