.link {
    a {
        text-decoration:none;
        padding:6px 0 6px 47px;
        color:#333;
        display:block;
        border-radius:6px;

        &:hover {
            background:rgba(0,0,0,0.05);
        }
    }
}