.check-fake-input-div {
    border: 1px solid #e5e9ec;
    height: 42px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 12px;
    margin-top: 2%;
    margin-bottom: 2%;
    justify-content: space-between;
}

.check-fake-input-span {
    font-size: 12px;
    color: #aaaaaa;
}

.check-fake-input-icon {
    display: flex;
   gap: 6px;
}