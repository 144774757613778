.active {
    background:#FDF2F8!important;

    &.bgSuccess {
        background:rgba(25,135,84,.04)!important;
    }

    &.bgWarning {
        background:rgba(255,193,7,.03)!important;
    }

    &.bgDanger {
        background:#FDF2F8 !important
    }
}

.inactive {
    opacity:.3;
    filter:brightness(0.9);
    pointer-events:none;
}

.bg_alert {
    background:#FDF2F8 !important;
}

.select {
    width:auto;
    min-width:130px;
    display:inline-flex;
    background:#E4E4E4;
    border:none;
    border-radius:100px;
    padding:2px 5px 2px 10px;

    >div {
        justify-content:space-between;
        width:100%;

        >div:first-of-type {
            background:transparent;
            font-size: 14px;
            text-align: center;

            >div {
                padding:0;

                >div {
                    margin:0;
                    padding:0;
                }
            }
        }

        >div[id] {
            right:0;

            >div {
                >div {
                    padding:8px 10px;
                }
            }
        }
    }
}

.badge {
    background-color: #E55050;
    border: none;
    padding: 3px 7px;
    top: -1px;
    margin-left: 4px;
    border-radius: 100px;
    color: #FFFFFF;
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    white-space: nowrap;
    position: relative;
}

// IMPRESSÃO
@media print {
    .inactive {
        display:none;
    }

    .active {
        td:not([colspan="100%"]) {
            &:first-child {
                padding-left:12px!important;
            }
        }   
        
        td:not([colspan="100%"]) {
            &:last-child {
                padding-right:12px!important;
            }
        } 
    }
}