.form_avaliacao {
    background: none;
    box-shadow: none;
    width: 640px !important;
    padding: 20px 20px 0 20px;
    font-size: 16px;
    color: #81878D;
}

.div_form_avl {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    /* font-size: 16px;
    color: #81878D; */
}

/* input */
.div_form_avl .Input_box__input__Iu9Xs {
    margin-top: 10px;
    height: 48px;
    width: 280px;
}

.div_form_avl {
    margin-bottom: 0 !important;
}

/* textarea */

.div_form_avl .textarea_box__textarea__fnaxZ {
    margin-top: 10px;
    height: 60px !important;
    width: 280px;
    overflow-y: hidden;
    scrollbar-width: thin;
    resize: none;
}

.div_form_avl .textarea_box__textarea__fnaxZ textarea {
    height: 60px !important;
    width: 280px;
    overflow-y: scroll;
    min-height: 60px;
    resize: none;
}

.div_form_avl .textarea_box__textarea__fnaxZ textarea::-webkit-scrollbar {
    width: 4px;
}

.div_form_avl {
    margin-bottom: 0;
}

.acoes_div{
    display: flex;
    /* border: 1px solid plum; */
    justify-content: space-between;
}

/* responsivo */
@media screen and (max-width:700px) {
    .div_form_avl .Input_box__input__Iu9Xs {
        width: 100%;
    }

    .div_form_avl .textarea_box__textarea__fnaxZ {
        width: 100%;
    }
}