/* index */

.obras-lista-head {
    z-index: 20 !important;
    position: relative;
}

.row-obras-1 {
    background-color: white;
    color: #334155;
}

.row-obras-disabled {
    opacity: .3;
    background: #eee;
}

.td-obras {
    color: inherit;
}

.td-obras-bold {
    font-weight: 700;
    color: inherit;
}

.p-obras {
    font-weight: 400;
    font-size: 11px;
    margin-bottom:0;
}


.sub-row-obras-1 {
    border-top: none;

    &:hover {
        background:#fff!important;
    }
}


.btn-quali {    
    display: inline-block;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: auto;
    min-width: 140px;
    border-radius: 100px;
    padding: 3px 12px;
    border: none;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 13px;

    &.red {
        background: #E55050;
    }

    &.blue {
        background-color: #3186C3;
    }

    &.green {
        background-color: #51C07D;
    }

    &.gray {
        background-color: #94A3B8;
    }
}

.btn-doc {
    border: none;
    width: auto;
    padding: 2px 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn-doc-novos {
    background-color: #E55050;
    border: none;
    width: auto;
    padding: 4px 7px;
    top: 2px;
    margin-left: 4px;
    border-radius: 100px;
    color: #FFFFFF;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    /* margin: 1%; */

    font-size: 10px;
    font-weight: 500;
    line-height: 9.68px;
    white-space:nowrap;
}

.obras-badge {
    background-color: #E55050;
    width: 16px;
    height:16px;
    position:relative;
    top: -1px;
    margin-left: 10px;
    border-radius: 50%;
    color: #FFF;
    font-size: 10px;
    font-weight: 500;
    text-align:center;
    line-height:16px;
    padding:0;
    display:inline-block;
    vertical-align:middle;
}

.obras-status-span {
    width: 120px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px 5px 10px;
    border-radius: 100px;
    background-color: #E2E8F0;
}

.dash-obras-title-div {
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
    margin-bottom: 1%;
    padding-right: 8%;
    /* apagar */
    gap: 2%;
}

.dash-obras-button {
    width: 159px;
    height: 33px;
    border-radius: 100px;
    padding: 10px;
    background-color: #3186C3;
    color: #FFFFFF;
    font-size: 11px;
    line-height: 13.31px;
    font-weight: 500;
    border: none;
}

.dash-obras-primary-th {
    font-size: 12px;
    font-weight: 800;
    line-height: 14.52px;
    color: black;
}

.dash-obras-secondary-th {
    font-size: 10px;
    font-weight: 700;
    line-height: 12.1px;
    align-self: self-end;
    color: black;
}

.dash-obras-primary-td {
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;
    color: black;
}

.dash-obras-div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 2%;
}

.dash-obras-table {
    /* width: 391.67px !important; */
    width: auto;
}

.dash-obras-div-span {
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.5%;
}

.dash-obras-image {
    width: 397px;
    height: 152px;
}

.td-dash-notification {
    position: relative;
}

.dash-obras-notification-icon-span-1 {
    width: 14px;
    height: 14px;
    border-radius: 100px;
    background-color: #E55050;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 15px;
    left: 19px;
    font-size: 8px;
    color: #FFFFFF;
}

.dash-obras-notification-icon-span-2 {
    width: 14px;
    height: 14px;
    border-radius: 100px;
    background-color: #E55050;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 16px;
    left: 44px;
    font-size: 8px;
    color: #FFFFFF;
}

.dash-obras-notification-icon-span-3 {
    width: 14px;
    height: 14px;
    border-radius: 100px;
    background-color: #E55050;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 14px;
    left: 70px;
    font-size: 8px;
    color: #FFFFFF;
}

.dash-obras-notification-icon-span-4 {
    width: 14px;
    height: 14px;
    border-radius: 100px;
    background-color: #E55050;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 14px;
    left: 94px;
    font-size: 8px;
    color: #FFFFFF;
}

.dash-obras-notification-icon-span-5 {
    width: 14px;
    height: 14px;
    border-radius: 100px;
    background-color: #E55050;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 14px;
    left: 120px;
    font-size: 8px;
    color: #FFFFFF;
}

.span-obras-novos {
    background-color: #E55050;
    border: none;
    width: 17px;
    height: 17px;
    border-radius: 100px;
    color: #FFFFFF !important;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 9px !important;
    line-height: 9px;
}

/* revisao - documentos */

.nome-generico-teste {
    display: flex;
    flex-direction: row;
    margin: 0;
    width: fit-content;
}

.revisao-doc-table {
    width: 553px;
}

.revisao-obras-title {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 14.52px;
    font-weight: 700;
    margin: 0.7% 0 0.7% 0;
}

.th-revisao-doc {
    width: 100px !important;
    font-size: 13px;
    font-weight: 700;
    line-height: 14.52px;
    color: #0F172A;
    word-wrap: break-word !important;
}

.td-revisao-doc {
    width: 100px !important;
    height: 99px;
    font-size: 13px;
    word-wrap: break-word !important;
}

.revisao-qualificacao-span-gray {
    background: #E2E8F0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 80px;
    height: 25px;
    border-radius: 100px;
    padding: 5px 10px 5px 10px;
    gap: 10px;
    border: none;

    color: #334155;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    cursor: default;
}

.revisao-qualificacao-span-yellow {
    background: #F6CA56;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 80px;
    height: 25px;
    border-radius: 100px;
    padding: 5px 10px 5px 10px;
    gap: 10px;
    border: none;

    color: #FFFFFF;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    cursor: default;
}

.revisao-qualificacao-span-green {
    background: #51C07D;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 80px;
    height: 25px;
    border-radius: 100px;
    padding: 5px 10px 5px 10px;
    gap: 10px;
    border: none;

    color: #FFFFFF;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    cursor: default;
}

/* revisao - termos e condicoes */