.group {   
    >div {
        cursor:pointer;

        &:first-child {
            border-radius:8px;
            padding:8px 15px;

            &:hover {
                background:rgba(0,0,0,.2);
            }
        }
    }

    >div>span {
        display:block;
        font-size:18px;
        font-weight:500;
        display:flex;
        align-items:center;

        svg {
            font-size:26px;
            margin-right:5px;
            top:-2px;
            position:relative;
        }
    }
    
    svg {
        font-size:21px;
    }

    .children {
        cursor:initial;
    }

    .collapse {
        display:none;
    }
}