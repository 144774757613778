.mini-card-gis {
    border: 1px solid #e9e9e9;
    border-radius: 8px;
    padding: 10px 22.5px 14px 22.5px;
    margin-top: 6%;
    background: #fff;
    width: 244px;
    height: 108px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: #a9afb5;
    font-size: 18px;
}

.mini-card-gis .Input_box__input__P081s input {
    color: #81878D;
}

/* card vazio */
.empty {
    background: #f0f5f8;
}

/* background vermelho */

.late {
    background: #F2383A;
    color: #fff !important;
}

.late label {
    color: #fff !important;
}

.late .Input_box__input__P081s input {
    color: white !important;
}

.late .Input_box__input__P081s.Input_box__input_focus__rcuV2 {
    background: rgb(211, 4, 4) !important;
}

.late :focus {
    background: rgb(211, 4, 4) !important;
}

.late::placeholder {
    color: #fff;
}

/* background azul */
.complete {
    background: #0396D8;
    color: #fff !important;
}

.complete label {
    color: #fff !important;
}

.complete .Input_box__input__P081s input {
    color: white !important;
}

.complete .Input_box__input__P081s.Input_box__input_focus__rcuV2 {
    background: #037db6 !important;
}

.complete :focus-visible {
    background: #037db6 !important;
}

.complete::placeholder {
    color: #fff;
}

/* INPUT SAC MES */
.input-sac-mensal {
    width: 100%;
    border: 1px solid #e5e9ec;
    border-radius: 4px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background ease 0.3s;
    position: relative;
    font-size: 13px;
    color: #81878D;
}

/* .input-sac-mensal::placeholder {
    color: #fff;
} */

/* INPUT SAC SEMANA */
.input-sac-semana {
    margin-top: 4px;
    width: 100%;
    border: 1px solid #e5e9ec;
    border-radius: 4px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background ease 0.3s;
    position: relative;
    font-size: 13px;
    color: #81878D;
}

.span-dia-semana {
    font-size: 18px;
    color: #81878D;
}

.late-s {
    background: #FDF2F2;
    border-right: 1px solid #e8edf1
}