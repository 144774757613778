.btnVM {
    position: relative;
    z-index: 999;
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    width: 40px;
    height: 40px;
    overflow: hidden;
    text-align: center;
    line-height: 44px;
    font-size: 23px;
    color: #555;
    padding: 5px;
    -webkit-box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.31);
    -moz-box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.31);
    box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.31);
}