.tableHead {
    position: sticky;
    top: 0;
    z-index: 4;
    background: #fff;
    box-shadow:0 5px 10px -7px rgb(0 0 0 / 10%);
    border-bottom:1px solid #e4e4e4;    

    tr:not(:first-child) {
        border-top:1px solid #e8edf1;
    }
}