.breadcrumbs {
    display:flex;
    align-items:center;
    margin:10px 0 25px;

    .title {
        font-size: 14px;
        font-weight: 700;
        color: #4c505b;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-right:7px;
    }
}

/* MOBILE */
@media screen and (max-width:500px){
    .breadcrumbs {
        margin-top:20px;
        overflow-x:auto;

        .title {
            font-size:18px;
        }
    }
}