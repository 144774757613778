.component_box {
    &.grab {
        cursor:grab;

        &:active {
            cursor:grabbing;
        }

        >div {
            pointer-events:none;
        }
    }
}