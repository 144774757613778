.user {
    position:relative;    
    padding:10px 20px;
    margin-left:10px;
    cursor:pointer;
    color:#757575;

    >div {
        display:flex;
        align-items:center;
    }

    &:hover {
        background:#eee;
    }

    .user__info_container {
        margin-left:12px;

        .user__name {
            font-size:14px;
            line-height:1.2;
            font-weight:500;
            display:block;
        }

        .user__info {
            font-size:13px;
            line-height:1.2;
            display:block;
        }
    }

    .user__photo {
        width:40px;
        height:40px;
        line-height:40px;
        font-weight:600;
        border-radius:50%;
        background:#00000029;
        color:#fff;
        text-align:center;
    }

    .user__menu {
        display:none;
        position:absolute;
        top:100%;
        right:0;
        width:auto;
        min-width:100%;

        ul {
            display:block;
            text-align:right;
            background:#0aa699;

            li {
                font-size:14px;
                padding:10px 20px;
                color:#fff;
                white-space:nowrap;

                &:hover {
                    background:rgba(0,0,0,0.1);
                }

                svg {
                    margin-left:8px;
                    font-size:14px;
                }
            }
        }
    }

    .arrow {
        margin-left:15px;
        font-size:14px;
    }
}

.user__active {
    background:#eee;

    .user__menu {
        display:block;
    }

    .arrow {
        transform:rotate(-180deg);
    }
}

@media screen and (max-width:768px){
    .user__photo {
        margin:0;
    }

    .user__info_container {
        display:none;
    }

    .user {
        padding-left:15px;
        padding-right:15px;

        .arrow {
            display:none;
        }
    }
}