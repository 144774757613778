.btnActionNews {
    cursor: pointer;
    margin: 0px 16px 0px 0px;
    background-color: inherit;
    color: #888;
    font-size: 14px;
    font-weight: 600;

    [data-box_icon] {
        margin-right:2px;
        position:relative;
        top:-2px;
    }    
}