.controle-tem {
    background: #f0f5f8;
}

.controle-tem-ultima {
    border-right: 1px solid #eaecee;
}

/* MAIN CONTAINER */

.container-temperaturas {
    padding: 20px;
    border-radius: 8px;
    background: #fff;
    gap: 20px;
    height: fit-content; 
}

.container-temperaturas img {
    height: 30px;
    width: auto;
}

.main-div-temperaturas {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container-temperaturas h3 {
    font-size: 18px;
    color: #3E4247;
}

.div-temperaturas {
    display: block;
    margin-bottom: 8px;
}

.span-temperaturas {
    font-weight: 300;
    color: #81878D;
    margin-right: 1%;
}

.span-title-temperaturas {
    margin-right: 0.2%;
    font-weight: 500;
    color: #81878D;
}

.logo-temperaturas{
    margin-left: 24px;
}