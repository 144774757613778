.detalhes {
    background:#fff!important;
    border-top:none;

    >td {
        padding:0!important;
    }

    .container_timeline {
        background:#FDF2F8;
        padding:5px 20px 15px;

        &.bgSuccess {
            background:rgba(25,135,84,.04)!important;
        }
    
        &.bgWarning {
            background:rgba(255,193,7,.03)!important;
        }
    
        &.bgDanger {
            background:#FDF2F8 !important
        }

        tr {
            background:transparent!important;
        }

        .info_1,
        .info_2 {
            display:flex;
            justify-content:space-between;
            gap:30px;

            >div {
                display:flex;
                gap:30px;
            }
            
            span {
                color:#94A3B8;
            }
        }

        .progressbar {
            position:relative;
            width:100%;
            height:5px;
            margin:0 0 15px;
            background:rgba(0,0,0,0.02);
            overflow:hidden;

            .bar {
                position:absolute;
                top:0;
                left:0;
                height:100%;
                border-radius:100px;
                transform: scaleX(0);
                transform-origin: left;
                transition:transform ease 1s;

                &.active {
                    transform:scaleX(1);
                }
            }
        }
    }

    .info_3 {
        display:flex;
        justify-content:space-between;
        padding:15px 0;

        >div {
            display:flex;
            gap:30px;
        }
    }

    .dash {
        min-height:calc(100vh - 348px);
        padding:10px 20px;
    }

    .table_container {
        border-radius:8px;
        border:1px solid #E2E8F0;
        width:100%;
        max-width:100%;
        height:100%;
        position:relative;
        z-index:1;

        .table_container_title {
            background:#F8FAFC;
            display:flex;
            align-items:center;
            justify-content:space-between;
            padding:20px;
            border-radius:8px;

            span {
                font-size:16px;
                color:#3E4247;

                &:nth-child(2) {
                    font-size:13px;
                }
            }
        }

        .table_container_body {
            [data-fullheight] {
                border:none;
                box-shadow:none;
            }

            tr {
                &.bg_light {
                    td {
                        b {
                            color:#81878D;
                        }
                    }
                }
            }

            th {
                text-transform:none;
                letter-spacing:0;
                font-size:13px;

                svg {
                    color:#0F172A;
                    font-size:13px;
                }
            }

            td {
                .badge {
                    background-color: #E55050;
                    border: none;
                    padding: 3px 7px;
                    top: -1px;
                    margin-left: 4px;
                    border-radius: 100px;
                    color: #FFFFFF;
                    text-align: center;
                    font-size: 10px;
                    font-weight: 500;
                    white-space: nowrap;
                    position: relative;
                }
            }
        }

        &.no_border {
            border:none;
        }

        .chat {
            margin-top:20px;
            min-height:400px;
        }
    }

    .float_alert {
        position: absolute;
        left: 100%;
        margin-left: 25px;
        z-index: 2;
        background: #E55050;        
        border-radius: 8px;
        width:290px;
        text-align:center;
        transform:translateX(-50px);
        opacity:0;
        transition:all ease 0s;
        box-shadow:0 10px 20px -10px rgba(0, 0, 0, 0.1);

        .float_alert_header {
            background:#d44949;
            padding: 8px 18px;
            color: #fff;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            font-weight:500;
            position:relative;

            [data-box_icon] {
                position:absolute;
                right:10px;

                svg {
                    color:#fff;
                    font-size:14px;
                }
            }
        }

        .float_alert_body {
            padding: 10px 18px;
            color: #fff;     
            font-size:13px;
            line-height:1.4;
        }

        &:before {
            content:'';
            position:absolute;
            top:22px;
            right:100%;
            width: 0; 
            height: 0; 
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;             
            border-right: 15px solid #E55050;             
        }

        &.active {
            transform:translateX(0);
            opacity:1;
            transition:all ease .5s;
        }
    }
}

// MOBILE
@media screen and (max-width:500px){
    .detalhes {
        .container_timeline {
            .info_2 {
                display:block;

                >div {
                    display:block;

                    >span {
                        display:block;
                    }
                }
            }
        }

        .float_alert {
            left: 0;
            bottom:100%;
            margin-left: 0;
            margin-bottom:25px;
            width:100%;
            transform:translateX(0);
            transform:translateY(-50px);
        
            &:before {
                top:100%;
                right:calc(50% - 5px);
                width: 0; 
                height: 0; 
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;                
                border-top: 15px solid #f00;         
                border-bottom:none;

            }
    
            &.active {
                transform:translateY(0);
                opacity:1;
                transition:all ease .5s;
            }
        }
    }
}

// IMPRESSÃO
@media print {
    .detalhes {
        .dash {
            padding-left:0;
            padding-right:0;
        }

        table {
            tbody {
                tr {
                    td {
                        &:first-child {
                            padding-left:20px;
                        }

                        &:last-child {
                            padding-right:20px;
                        }
                    }
                }
            }
        }
    }
}