.modalBody {
    padding:10px 30px 30px;

    form {
        padding:0;
        box-shadow:none;
    }
}

/* MOBILE */
@media screen and (max-width:768px){
    .modalBody {
        padding:10px 24px 24px;
    }
}