.textNewsDivContainer {
    padding: 25px 30px 15px 30px;
    text-align: left;
}

@media screen and (max-width: 375px){
    .textNewsDivContainer{
        padding: 15px 20px 15px 20px;
    }
}
@media screen and (max-width: 820px ) {
    .textNewsDivContainer {
        padding: 15px 20px 0 20px;
    }
}