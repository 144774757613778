.td-vistoria-bold {
    font-weight: 700 !important;
}

.gerenciador-obras-transition {
    transition: all ease .5s;
}

.gerenciador-obras-col-disabled {
    pointer-events: none;
    opacity:  0.2;
}