.sidebar {
    position:fixed;
    left:0;
    background:#0aa699;
    width:50px;
    height:calc(100% - 61px);
    z-index:99;
    transition:width ease .3s;
    overflow:hidden;
    top:61px;

    ul {
        list-style:none;
        margin-top:17px;
        padding-left:0;

        li {
            color:#fff;            
            display:inline-flex;
            align-items:center;
            justify-content:start;
            white-space:nowrap;
            cursor:pointer;     
            transition:width ease .3s; 
            width:50px;

            a {
                display:block;
                width:100%;
                color:#fff;
                padding:15px 17px;
                text-decoration:none;
                position:relative;

                &:after {
                    content:'';
                    position:absolute;
                    top:calc(50% - 6px);
                    right:0;
                    width: 0; 
                    height: 0; 
                    border-top:7px solid transparent;
                    border-bottom:7px solid transparent;                     
                    border-right:7px solid #e5e9ec; 
                    transition:transform ease .3s;
                    pointer-events:none;
                    transform:translateX(10px);
                }

                [data-box_icon] {
                    pointer-events:none;
                }

                svg,
                lord-icon {
                    color:#fff;
                }

                &[class="active"] {
                    background:transparent;
                    color:rgba(0,0,0,.3);
                    font-weight:600;
                    
                    &:after {
                        transform:translateX(0);
                    }

                    svg,
                    lord-icon {
                        color:rgba(0,0,0,.3);
                    }
                }                
            }

            &:hover {
                background:rgba(0,0,0,0.1);
            }

            span {
                opacity:0;
                font-size:14px;
                margin-left:10px;
                transition:opacity .2s ease 0s;
            }
        }
    }    

    &.sidebar__active {
        width:240px;

        li {
            width:240px;

            span {
                opacity:1;
                transition:opacity .3s ease .1s;
            }
        }
    }
}

@media screen and (max-width:768px){
    .sidebar {
        position:relative;
        width:100%;
        height:auto;
        transition:none;
        background:#fff;

        ul {
            display:flex;
            margin:0;
            padding:0;

            li {
                width:auto;

                a {
                    padding:15px 10px;
                    text-transform:uppercase;
                    font-weight:900!important;
                    font-size:20px;
                    color:rgba(0, 0, 0, 0.3)!important;
                    position:relative;

                    &:first-child {
                        padding-left:20px;
                    }

                    &:last-child {
                        padding-right:20px;
                    }

                    &:after {
                        display:none;
                    }

                    svg {
                        display:none;
                    }

                    span {
                        font-size:16px;
                    }
                }

                [class="active"] {
                    position:relative;
                    border-bottom:3px solid rgba(0, 0, 0, 0.3);
                }

                span {
                    opacity:1;
                    margin-left:0;
                }

                &:hover {
                    background:#fff;
                }
            }
        }

        &.sidebar__active {
            width:100%;
            transition:none;
            background:#fff;

            li {
                width:auto;
            }
        }
    }
}

@media print {
    .sidebar {
        display:none;
    }
}

/********************* IFRAME ************************/

.sidebar {
    top:0;
    height:100%;
}

/********************* FIM IFRAME ************************/