.formNews {
    all:unset ;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%!important;
    height: 40px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 0!important;
    box-shadow: none!important;
}

.divFormNews{
    position: relative;
}
