.dashboard {
    width:516px;
    max-width:100%;

    [data-fullheight] {
        height:calc(100vh - 194px);
    }

    table {
        thead {
            tr {
                th:not(:first-child) {
                    width:1px;
                    white-space:nowrap;
                }
            }
        }

        tbody {
            tr {
                td:first-child {
                    max-width:200px;
                }

                td:not(:first-child) {
                    width:1px;
                    white-space:nowrap;
                }

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    td {
                        color:red;
                    }
                }
            }
        }
    }

    &.width__auto {
        width:auto;
        min-width:500px;        

        [data-fullheight] {
            overflow-x:hidden;
        }
    }
}

@media screen and (max-width:768px){
    .dashboard {
        margin-bottom:30px;
    }
}