.iframe {
    width:100%;
    height:500px;
    max-height:calc(100vh - 200px);
}

@media screen and (max-width:768px){
    .iframe {
        height:40vh;
    }
}