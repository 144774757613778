.status {
    background: #E2E8F0;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 25px;
    border-radius: 100px;
    padding: 5px 10px 5px 10px;
    gap: 10px;
    border: none;
    white-space:nowrap;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    cursor: default;

    &.reprovado {
        background:#E2E8F0;
        color:#334155;
    }

    &.ressalva {
        background:#9bffc3;
        color:#334155;
    }

    &.analise {
        background:#F6CA56;
    }

    &.aprovado {
        background:#51C07D;
    }

    &.pendente {
        background:#ddd;
        color:#aaa;
    }
}

.documentos {
    table {
        margin-bottom:15px;

        thead {
            box-shadow:none;

            tr {
                background:#F8FAFC!important;

                th {
                    background:#F8FAFC!important;
                    color:#0F172A;
                    text-transform:none;
                    letter-spacing:0;

                    &:first-child {
                        span {
                            padding-left:8px;
                        }
                    }

                    &:last-child {
                        >span {
                            padding-right:12px;
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                td:first-child {
                    span {
                        padding-left:15px;
                    }
                }

                td:last-child {
                    >span {
                        padding-right:12px;
                    }
                }
            }
        }
    }

    &:last-child {
        margin-bottom:0;
    }

    .small {
        font-size:11px;
    }
}