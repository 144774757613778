.counter {
    position: fixed;
    z-index: 9;
    right: 0;
    bottom: 30px;
    background: #fff;
    padding:8px 15px;
    transform: translateX(100%);
    transition: all ease .3s;
    font-size:14px;
    box-shadow:0 .5rem 1rem rgba(0,0,0,.15)!important;

    svg {
        margin-left:8px;
        cursor:pointer;
    }

    .btn__hide {
        position: absolute;
        cursor: pointer;
        right: 100%;
        height: 100%;
        width: 10px;
        background: #8b91a0;
        top: 0;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        color: #fff;

        svg {
            color:#fff;
            margin-left:0;
        }
    }

    [data-icon] {
        &:before {
            display:none;
        }
    }

    &.active {
        transform:translateX(0);
    }
}

@media screen and (max-width:500px){
    .counter {
        font-size:18px;

        svg {
            font-size:18px;
        }
    }
}