.form {
    background:#fff;
    padding:20px; 
    border-radius:4px;
    box-shadow:0 2px 4px -2px rgb(0 0 0 / 50%);
    max-width:100%;

    [data-input] {
        margin-bottom:10px;

        &:last-of-type {
            margin-bottom:0;
        }
    }

    [data-textarea] {
        margin-bottom:10px;

        &:last-of-type {
            margin-bottom:0;
        }
    }

    [data-select] {
        margin-bottom:10px;

        &:last-of-type {
            margin-bottom:0;
        }

        [data-value] {
            font-size:13px;
        }
    }

    [class="DatePicker "] {
        width:100%;
        z-index:0;
    }

    &.full__height {
        height:calc(100vh - 176px);
        overflow-y:auto;
    }

    &.no__border {
        padding:0;
        box-shadow:none;
    }

    &.no__background {
        background:transparent;
        padding:0;
        box-shadow:none;
    }
}

/* MOBILE */
@media screen and (max-width:768px){
    .form {
        [data-select] {
            [data-value] {
                font-size:16px;
            }
        }
    }
}