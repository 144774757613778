.buscaNews {
    width: 360px;
    height: 55px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    margin: 0px 0 0;
    padding: 12px 20px;
}

@media screen and (max-width: 540px) {
    .buscaNews {
        max-width: 80%;
        height: 55px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 820px) {
    .buscaNews {
        margin-left: 30px;
        width: 755px;
        height: 55px;
        margin-bottom: 30px;
    }
}