.text-secondary {
    opacity:.4!important;
}

.flex-1 {
    flex:1;
}

.pointer-events-none {
    pointer-events:none;
}

.font-weight-bold {
    font-weight:bold;
}

.font-weight-300 {
    font-weight:300;
}

.font-weight-400 {
    font-weight:400;
}

.font-weight-500 {
    font-weight:500;
}

.cursor-initial {
    cursor:initial!important;
}

.modal-dialog-xl {
    width:1400px;
    max-width:97vw;
}

.modal-header {
    .btn-close {
        box-shadow:none!important;
        outline:none!important;
        background:none;
        opacity:1;

        &:after {
            content:'';
            background:#97a3b3;
            font-weight:bold;
        }
    }
}

tr.bg_light {
    background:#F8FAFC!important;
}

tr.text-warning {
    td {
        color: rgba(var(--bs-warning-rgb),var(--bs-text-opacity))!important;
    }
}

tr.text-success {
    td {
        color: rgba(var(--bs-success-rgb),var(--bs-text-opacity))!important;
    }
}

tr.text-danger {
    td {
        color: rgba(var(--bs-danger-rgb),var(--bs-text-opacity))!important;
    }
}

tr.text-primary {
    td {
        color: #0090d9 !important;
    }
}

.spinner-border {
    position: relative;
    top: 1px;
    left: 4px;
    border-color:#97a3b3;
    border-right-color:transparent;
    border-width:2px;
}

.bg-primary-dark {
    background:#10459E;
}

body {
    .modal[role="dialog"] {
        .modal-dialog {
            .modal-content {
                // display:none;
            }
        }

        &:last-of-type {
            .modal-dialog {
                .modal-content {
                    display:block!important;
                }
            }
        }

        &:nth-of-type(2) {
            background:rgba(0,0,0,0.7);
        }
    }
}

@media print {
    body.modal-open {
        #root {
            display:none;
        }
    }

    .modal {
        position:relative;
        width:100vw;
        overflow:visible;
    }

    .infinite-scroll-component {
        overflow:visible!important;
        width:100%;
    }

    .modal-backdrop {
        display:none!important;
    }

    .modal-dialog {
        width:100vw;
        height:100vh;
        max-width:100vw;
        border-radius:0;
        margin:0;
        position:relative;
    }

    .modal-content {
        border:none;
    }
}