.detailSpan {
    margin: 0 10px 0 0;
    cursor: pointer;
    color: #999;
    font-size: 14px;

    [data-box_icon] {
        position:relative;
        top:-2px;
    }
}

@media screen and (max-width: 375px) {
    .detailSpan {
        font-size: 17px;
        margin: 0;
    }
}