/* INDEX.JSX */

.sac-status {
    align-items: center;
    border-radius: 100px;
    display: flex;
    height: 25px;
    justify-content: center;
    padding: 5px 10px;
    width: 120px;
}

.--gray-status {
    background: #81878d;
    color: #fff;
}

.--yellow-status {
    background: yellow;
    color: #81878d;
}

.--red-status {
    background: red;
    color: #fff;
}

.--blue-status {
    background: #10459e;
    color: #fff;
}



/* DETALHE.JSX */

.sac-tr .Table_tableContainer__k6DPQ .Table_text__limit_30__\+iqf4 th>span,
.Table_tableContainer__k6DPQ .Table_text__limit_30__\+iqf4 td>span {
    max-width: 100%;
}

.span-bold-sac {
    font-weight: 500;
    color: #81878d;
}

.sac-detalhe-btn {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: flex-end;
}

/* .sac-colspan {
    width: 100%;
} */

.sac-row {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sac-table-1 {
    width: 450px;
}

.sac-tr {
    border-top: none;
}

.sac-td_full_width {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.sac-form {
    width: 100% !important;
    box-shadow: none;
    padding: 0;
    background: inherit;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

.sac-form .textarea_box__textarea__wyqKt textarea {
    height: 100px;
    resize: none;

}

.sac-form .sac-input {
    padding: 8px !important;
    margin-bottom: 4%;
    background: #fff !important;
}

.sac-w-40 {
    width: 40% !important;
}

.sac-w-40 .infinite-scroll-component__outerdiv {
    z-index:3;
    height:100%;
}

.sac-w-40 .infinite-scroll-component {
    height:100%!important;
}

.sac-w-40 .infinite-scroll-component>div {
    height:100%;
}

.sac-w-40 table td {
    padding-top:5px;
    padding-bottom:5px;
}

.sac-w-40 table td:hover {
    background:#fff!important;
}

.sac-w-40 table tr:first-child td {
    padding-top:20px;
}

.sac-w-40 table tr:last-child td {
    padding-bottom:20px;
}

.td_full_width>span {
    max-width: none !important;
}

/* MODAL */

.sac-form-modal {
    display: flex;
    flex-direction: row;
    gap: 20px
}

.sac-modal-title {
    font-weight: 300;
    font-size: 27px;
}

.sac-form-modal .textarea_box__textarea__wyqKt textarea {
    height: 84px;
}

.sac-modal-header {
    padding: 30px 0px 10px !important;
}

@media screen and (max-width: 750px) {
    .sac-row {
        flex-direction: column;
    }

    .sac-w-40 {
        width: 100vw !important;
    }

    .sac-form-modal {
        display:block;
    }
}