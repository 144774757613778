.breadcrumb {
    font-size: 14px;
    font-weight: 700;
    color: #4c505b;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor:pointer;

    &:not(first-child):before {
        content:'/';
        margin:0 7px;
        color:#4c505b;
    }

    &:first-child {
        &:before {
            display:none;
        }
    }

    &:last-child {
        color:#f2b636;        
        cursor:initial;
        pointer-events:none;
    }
}

/* MOBILE */
@media screen and (max-width:500px){
    .breadcrumb {
        white-space:nowrap;
        font-size: 18px;
    }
}