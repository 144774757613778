.box__textarea {
    background: #fff;
    border: 1px solid #e5e9ec;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background ease .3s;

    &.disabled {
        opacity:.4;
        border:1px solid #cacdcf;
        pointer-events:none;
    }

    &.readonly {
        pointer-events:none;
    }

    &.box__textarea_focus {
        background: #f4f5f7;
    }

    &.border__alert {
        border-color:red;
    }

    textarea {
        border: none;
        outline: none;
        width: 100%;
        height: 150px;
        min-height: 80px;
        text-align: left;
        padding: 8px 12px;
        font-size: 13px;
        background: transparent;
    }

    label {
        padding-right: 8px;
        font-weight: 500;
        font-size: 13px;
    }
}

.hidden {
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    height:calc(100% - 56px);
    opacity:0;
    pointer-events:none;
}

/* MOBILE */
@media screen and (max-width:768px) {
    .box__textarea {
        textarea {
            font-size: 16px;
        }
    }
}

// COMPONENTE textEditor 

// .rdw-editor-wrapper{
//     width: 438px;
// }

.wrapperClassName {
    width: -webkit-fill-available;
    border-radius: 3px 3px 0 0 !important;
    border: none;
    font-size: 13px;
}

.rdw-editor-main {
    all: unset;
    height: 150px !important;
    overflow: auto;
    box-sizing: border-box;
}