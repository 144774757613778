.remove {
    position:absolute;
    top:-4px;
    right:0;
    font-size:11px;
    z-index:2;

    svg {
        font-size:11px;
        margin:0;
    }

    [data-icon]{
        cursor:pointer;

        &:before {
            display:none;
        }
    }
}

.files {
    a {
        display:block;
        padding:3px 0;
    }
}

.file__link {
    color:#8b91a0;
    font-size:14px;
    text-decoration:none;

    svg {
        position:relative;
        top:-1px;
        margin-right:4px;
    }
}

@media screen and (max-width:580px){
    .icon {
        font-size:24px!important;
    }
}