.historico {
    background:#fff!important;
    border-top:none;
    position:relative;
    z-index:1;

    >td {
        padding-bottom:20px;
    }

    .status {
        background: #E2E8F0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 25px;
        border-radius: 100px;
        padding: 5px 10px 5px 10px;
        gap: 10px;
        border: none;
        white-space:nowrap;
        color:#334155;
        font-weight: 400;
        font-size: 12px;
        line-height: 14.52px;
        cursor: default;

        &.reprovado {
            background:#FFCBCB;
        }

        &.ressalva {
            background:#9bffc3;
        }

        &.analise {
            background:#F6CA56;
        }

        &.aprovado {
            background:#ACE4A8;
        }

        &.pendente {
            background:#ddd;
            color:#aaa;
        }
    }

    table {
        thead {
            tr {
                th {
                    text-transform:none;
                    letter-spacing:0;
                    background:#fff!important;
                    height:auto;
                }

                &:first-child {
                    th {
                        background:#F8FAFC!important;
                        text-transform:uppercase;
                    }
                }
            }
        }

        tbody {
            tr {
                &.alert {
                    td {
                        background:#F8FAFC;
                    }
                }
            }
        }
    }

    [data-fullheight] {
        box-shadow:none;
    }

    [class="infinite-scroll-component__outerdiv"] {
        display:flex;
        height:100%;
        width:100%;

        >div {
            flex:1;
        }

        [data-fullheight] {
            min-height:100%;
        }
    }

    .overflow {
        width:100%;
        overflow-x:auto;
    }

    .container_revisao {
        gap:20px;
    }
}

// DESKTOP
@media screen and (min-width:500px){
    .historico {
        .container_revisao {
            max-width:calc(100vw - 98px);
        }
    }
}

// MOBILE
@media screen and (max-width:500px){
    .historico {
        .container_revisao {
            display:block;

            >div {
                [data-fullheight] {
                    width:100%;
                    margin-bottom:30px; 
                }

                &:last-child {
                    [data-fullheight] {
                        margin-bottom:0;
                    }
                }
            }
        }
    }
}

// IMPRESSÃO
@media print {
    .historico {
        .container_revisao {
            display:block;

            >div {
                [data-fullheight] {
                    width:100%;
                    margin-bottom:30px;      
                    
                    thead {
                        tr {
                            th {
                                &:first-child {
                                    padding-left:12px!important;
                                }

                                &:last-child {
                                    padding-right:12px!important;
                                }
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                &:first-child {
                                    padding-left:12px!important;
                                }

                                &:last-child {
                                    padding-right:12px!important;
                                }
                            }
                        }
                    }
                }

                &:last-child {
                    [data-fullheight] {
                        margin-bottom:0;
                    }
                }
            }
        }
    }
}