.titleNews {
    margin-bottom: 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 32px;
    gap: 2%;
}

.iconTitle{
    font-size: 17px!important;
}

