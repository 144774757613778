.chart {
    position:relative;
    margin-bottom: 20px;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);
    background:#fff;
    padding:1.5rem;

    h1 {
        margin-bottom:0;
    }

    .loader {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background:rgba(255,255,255,0.9);
        z-index:2;        
        display:flex;
        align-items:center;
        justify-content:center;
        border-radius:6px;
    }

    .empty {
        height:calc(100% - 30px);
        display:flex;
        align-items:center;
        justify-content:center;
        text-align:center;
        position:relative;

        p {
            position:relative;
            z-index:1;
        }

        [data-box_icon] {
            display:block;

            svg {
                font-size:18px;
                margin-bottom:10px;
            }
        }
    }
}