.card {
    width:100%;
    box-shadow:0 1px 3px rgba(27, 23, 30, 0.1);
    border-radius:0.25rem;
    margin-bottom:20px;

    .container {
        display:flex;

        .info {
            padding:1.5rem;
            color:#fff;
            font-size:0.74375rem;
            flex: 0 0 calc(100% - 100px);
            max-width: calc(100% - 100px);

            .title {
                font-size:0.74375rem;
                text-transform:uppercase;
                letter-spacing:0.05rem;
                padding-bottom:3px;
            }

            .info1 {
                padding:0 0 5px;

                .number {
                    font-size:1.82rem;
                    font-weight:600;
                }

                .label {
                    margin-left:5px;
                    font-size:0.875rem;
                }
            }

            .info2, .info3 {
                overflow:hidden;
                text-overflow:ellipsis;
                max-width:50%;

                .number {
                    padding:1px 6px;
                    background:rgba(255,255,255,.1);
                    border-radius:2px;
                    font-size:0.775rem;
                    margin-right:5px;
                    white-space:nowrap;

                    &.light {
                        background:rgba(0,0,0,0.05);
                    }
                }

                .label {
                    font-size:0.875rem;
                    white-space:nowrap;
                }
            }

            .info3 {
                margin-left:15px;                
            }

            &.light {
                color:#8b91a0;
            }
        }

        .icon {
            background:rgba(255,255,255,.3);
            display:flex;
            align-items:center;
            justify-content:center;    
            flex: 0 0 100px;
            max-width: 100px;    
            
            [data-box_icon] {
                pointer-events:none;
            }
            
            svg, lord-icon {
                color:#fff;
                font-size:36px;
            }

            &.light {
                svg, lord-icon {
                    color:#8b91a0;
                }
            }
        }
    }
}

@media screen and (max-width:1600px){
    .card {    
        .container {    
            .info {
                flex: 0 0 calc(100% - 80px);
                max-width: calc(100% - 80px);

                .info2,
                .info3 {
                    max-width:100%;
                }

                .info3 {
                    margin-top:10px;
                    margin-left:0;
                }
            }

            .icon {
                flex: 0 0 80px;
                max-width: 80px;  
            }
        }
    }
}