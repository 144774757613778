.userCommentParagraph {
    opacity: 1;
    font-size: 13px;
    color: #777;
    line-height: 1.4;
    margin-bottom: 0 !important;
    overflow-wrap: break-word;
    overflow: hidden;
    text-align: justify;
    max-width: 555px;
    box-sizing: border-box;
}

@media screen and (max-width: 820px) {
    .userCommentParagraph {
        overflow-wrap: break-word;
    }
}