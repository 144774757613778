.qtd_cards {
    display:flex;
    gap:30px;
    margin:0 0 25px;

    .qtd_cards_label {
        color:#81878D;
        margin-bottom:0;
        display:block;
        cursor:pointer;

        svg {
            width:8px;
            margin-left:10px;
        }

        &.active {
            color:#00B0FF;
        }
    }

    .qtd_cards_items {
        .module {
            display:block;
            color:#A1A7AE;
            margin-bottom:16px;
            cursor:pointer;

            span {
                margin-left:5px;

                &.news {
                    color:#00B0FF;
                }
            }    

            &:first-child {
                margin-top:16px;
            }

            &:last-child {
                margin-bottom:0;
            }   

            &.disabled {
                opacity:.5;
                cursor:initial;
            }
        }
    }
}

// MOBILE
@media screen and (max-width:500px){
    .qtd_cards {
        display:block;
        border-bottom:1px solid #e4e4e4;
        padding-bottom:10px;
        margin-bottom:15px;

        .qtd_cards_label {
            display:flex;
            justify-content:space-between;
            margin-bottom:8px;
        }

        .qtd_cards_items {
            .module {
                margin-bottom:6px;

                &:first-child {
                    margin-top:6px;
                }

                &:last-child {
                    margin-bottom:16px;
                }
            }
        }
    }
}