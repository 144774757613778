.calendar__title {
    font-weight:300;
    margin:0 0 8px;
    padding-bottom:8px;
    font-size:1.4rem;
    color:#4c505b;
    position:relative;
    z-index:10;
    display:flex;
    justify-content:space-between;
    align-items:center;    
    border-bottom:1px solid #ccc;

    &.active {
        color:#0090d9;
        font-weight:400;
    }

    svg {
        font-size:17px;
        cursor:pointer;
        //display:none;
    }

    [data-select], [data-input] {
        width:auto;
        height:38px;
        padding:0 5px 0 0;
        background:transparent;
        border:none;

        >div {
            background:transparent;
            padding:0;
        }

        >div>div {
            background:transparent;
            padding:0;
        }

        [class=" css-18743zk-placeholder"], [class=" css-qc6sy-singleValue"], label, input {
            font-size:14px;
        }
    }
}

@media screen and (max-width:768px){
    .title {
        [data-select] {
            padding-top:3px;
            padding-bottom:3px;
        }
    }    

    .calendar__title {
        >span {
            width:100%; 
        }
        
        border-bottom:none;
        padding-bottom:0;
        margin-bottom:0;

        [data-filter] {
            height:40px;
        }
    }
}