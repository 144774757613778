.galeria-container {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.container-load {
    height: 35vh;
    width: 90vw;
    display: flex;
    justify-content: start;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .galeria-container {
        justify-content: start;
        align-items: center;
    }
}