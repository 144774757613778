.userImage {
    display: block;
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: #00000029;
    text-align: center;
    border-radius: 50%;
    margin-bottom: inherit;
    margin-right: 2px;
}