.mainSuccess{
    display: flex;
    flex-direction: column;
}

.textSucess {
    position: absolute;
    z-index: 50;
    top: 20px;
    left: 25px;
    background-color: #fff;
    padding: 3px 10px;
    box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 15%);
    color: #28a745 !important;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

/* MOBILE SCREEN */
@media screen and (max-width: 375px) {
    .textSucess {
        width: 80%;
        height: auto;
        font-size: 17px;
    }
}

@media  screen and (max-width: 390px) {
    .textSucess {
        width: 80%;
        height: auto;
        font-size: 17px;
    }
}

@media  screen and (max-width: 414px) {
    .textSucess {
        width: 80%;
        height: auto;
        font-size: 17px;
    }
}