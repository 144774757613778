.textNewsContainer {
    padding-bottom: 30px;
    padding-right: 30px;
    padding-left: 30px;
    text-align: left;
}

@media screen and (max-width: 375px) {
    .textNewsContainer{
        padding: 0 20px 20px 20px;

    }
}
