.switcher_container {
    display:inline-block;
    vertical-align:middle;
    margin-left:5px;

    .switcher {
        background:#bdc3cb;
        width:35px;
        height:18px;
        border-radius:50px;
        position:relative;
        cursor:pointer;
        transition:background ease .3s;

        .switch {
            width:14px;
            height:14px;
            background:#fff;
            border-radius:50%;
            position:absolute;
            top:2px;
            left:2px;
            transition:left ease .3s;
        }

        &.on {
            background:#10459e;

            .switch {
                left:calc(100% - 16px);
            }
        }
    }
}