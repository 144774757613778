.mainContainer {
    margin: 30px auto;
}

.rowNews {
    --bs-gutter-x: 4rem;
}

.buttonDelete {
    position: absolute !important;
    top: -20px;
    right: -25px !important;
    z-index: 2;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 5px 20px -10px rgb(0 0 0 / 50%);

    [data-box_icon] {
        position:relative;
        top:-2px;
        
        [data-icon] {
            &:before {
                display:none;
            }

            lord-icon {
                width:24px!important;
                height:24px!important;
            }
        }
    }
}

/* mensagem de erro quando não tem a hashtag */

.erroHashtag{
    background: #dadada;
    color: #888;
    text-align: center;
    padding: 30px;
}

.erroHashtagIcon{
    font-size: 50px
}

.erroHashtagTitle{
    font-weight: 600!important;
    font-size: 17px!important;
    margin: 12px 0 10px!important;
    color: #4c505b;
}

@media screen and (max-width: 375px) {
    .mainContainer {
        margin: 0 !important;
        width: 100%;
    }
}

/* SURFACE DUO */
@media screen and (max-width: 540px) {
    .mainContainer {
        margin: 0 !important;
        width: 100%;
    }
}

/* IPAD SCREEN */
@media screen and (max-width: 820px) {
    .mainContainer {
        margin: 0 !important
    }
}

/* INPUT FOTO */

.inputFotoStyle {
    width: 100%;
    height: 238px;
    margin-bottom: 3%;
    background: #eee;
    color: #aaa;
    position: relative;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
}

/* MODAL SIZE */

.modalNewsImage {
    width: 100% !important;
    height: auto;
    border-radius: 5px;
}

// a:link {
//     color: #222222;
// }

// a:visited {
//     color: #918b8b
// }

.react-datepicker-popper {
    z-index: 10;
}

.documentoDiv{
    display: block;
    width: 100%;
    height: 500px;
}