/* INPUT ORIGINAL */

.inputNews {
    width: calc(100% - 50px);
    height: 36px;
    border: 1px solid #00000029;
    margin-left: 2%;
    padding: 7px 0px 7px 7px;
    overflow: hidden;
    white-space: nowrap;
    transition: all ease .3s;
    font-size: 14px;
}

/* INPUT EMOJI */

.inputStyle {
    width: calc(100% - 50px);
    height: 36px;
    border: 1px solid #00000029;
    margin-left: 2%;
    padding: 7px 0px 7px 7px;
    overflow: hidden;
    white-space: nowrap;
    transition: all ease .3s;
    font-size: 14px;
}

.emoji-icon {
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: -7px;
    color: #00000029;

}

.inputDiv {
    position: relative;
}

.inputDiv .EmojiPickerReact.epr-main {
    height: 250px !important;
    width: auto!important;
    overflow: visible;
    position: absolute;
    top: -260px;
    right: 0px;
    box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 15%);
    transition: all .25s ease-in-out;
    --epr-emoji-size: 24px;
    --epr-emoji-padding: 4px;
}

.iconEmoji {
    all: unset;
    position: absolute !important;
    right: 11px;
    top: -13px;
    height: 18px;
    width: 18px;
    cursor: pointer;
}
