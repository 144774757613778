.cropperBack {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index:9;
  background-color: rgba(0, 0, 0, .3);
}

.cropperRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 800px;
  max-height: 90vw;
  background-color: white;
  border-radius: 8px;
}

.cropContainer {
  position: relative;
  width: 100%;
  height: 500px;
  max-height:calc(100vh - 300px);
  background-color: #333333;
  border-radius: 6px 6px 0 0;
  @media screen and (max-width: 500px) {
    height: 400px;
  }
}

.cropButton {
  border-radius: 8px;
  border: 1px solid transparent;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
  transition: border-color 0.25s;

  background-color: white;
  color: #505050;

  width: 100%;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 16px;
  gap: 10px;
}

.sliderContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 10px;

  h3 {
    color: black;
    margin: 0;
    padding: 0;

    font-size: 16px;
    font-weight: 500;
    margin-bottom: 6px;
  }

  input {
    flex-grow: 1;
  }
}