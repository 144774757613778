.description {
    padding:50px 0 0;
    
    h2 {
        margin-bottom:20px;
    }

    &:first-child {
        margin-top:0;
    }

    table {
        width:100%;

        thead {
            tr {
                th {
                    white-space:nowrap;
                    padding:10px 10px;
                    border:1px solid #bbb;
                }
            }
        }

        tbody {
            tr {
                td {
                    white-space:nowrap;
                    padding:7px 10px;
                    border:1px solid #bbb;

                    &:first-child {
                        font-style:italic;
                    }

                    &:last-child {
                        white-space:initial;
                    }
                }
            }
        }
    }
}