.item {
    background:transparent!important;

    td {
        padding-left:0!important;
        padding-right:0!important;

        [data-input] {
            input {
                padding:6px 8px;
            }
        }
    }
}