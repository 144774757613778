.button{
    background-color: #fff;
    color: #182141;
    margin:0 10px;
    border: 3px solid #f2b636;
    padding: 20px 40px;
    font-weight: 700;
    font-size: .875rem;
    letter-spacing: 1.75px;
    border-radius: 60px;
    transition: all;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-property: all;
    text-transform:uppercase;
    
    &:hover{
        background-color: #f2b636;
        color: #fff;
        transition: all;
        transition-duration: 0.5s;
        transition-timing-function: ease;
        transition-delay: 0s;
        transition-property: all;
    }

    &.finished {
        background-color:#28a745;
        color:#fff;
        pointer-events:none;
    }
}

// MOBILE
@media screen and (max-width:700px){
    .button {
        padding: 13px 20px;
    }
}